import(/* webpackMode: "eager" */ "/app/apps/huutokaupat-frontend/public/images/hero-background.svg");
;
import(/* webpackMode: "eager" */ "/app/apps/huutokaupat-frontend/slices/LinkBlocks/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/huutokaupat-frontend/slices/LinkGroup/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavItemDropdown"] */ "/app/apps/huutokaupat-frontend/slices/MainNavigationItem/NavItemDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavItemUserMenu"] */ "/app/apps/huutokaupat-frontend/slices/MainNavigationItem/NavItemUserMenu.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/huutokaupat-frontend/slices/RegistrationIconBlocks/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/huutokaupat-frontend/slices/RegistrationLinkBlocks/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/huutokaupat-frontend/src/components/Category/CategoryMenu.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/huutokaupat-frontend/src/components/Container/Container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/huutokaupat-frontend/src/components/EntryList/FrontPageEntryList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FavoritesList"] */ "/app/apps/huutokaupat-frontend/src/components/Favorites/FavoritesList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/huutokaupat-frontend/src/components/Header/HeroCountdown.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/huutokaupat-frontend/src/components/Link/LinkButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarSection","SidebarContent"] */ "/app/apps/huutokaupat-frontend/src/features/EntryPage/SidebarSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FrontPageAnalytics"] */ "/app/apps/huutokaupat-frontend/src/features/FrontPage/FrontPageAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavItem"] */ "/app/apps/huutokaupat-frontend/src/features/SiteHeader/MainNavigationItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextImage"] */ "/app/node_modules/.pnpm/@prismicio+next@1.7.0_@prismicio+client@7.16.0_next@14.2.24_patch_hash=e20141b84cb46316_8d7e14e20c5de64434d847e040b8d97e/node_modules/@prismicio/next/dist/PrismicNextImage.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextLink"] */ "/app/node_modules/.pnpm/@prismicio+next@1.7.0_@prismicio+client@7.16.0_next@14.2.24_patch_hash=e20141b84cb46316_8d7e14e20c5de64434d847e040b8d97e/node_modules/@prismicio/next/dist/PrismicNextLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicPreviewClient"] */ "/app/node_modules/.pnpm/@prismicio+next@1.7.0_@prismicio+client@7.16.0_next@14.2.24_patch_hash=e20141b84cb46316_8d7e14e20c5de64434d847e040b8d97e/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicToolbar"] */ "/app/node_modules/.pnpm/@prismicio+react@2.9.2_@prismicio+client@7.16.0_react@18.3.1/node_modules/@prismicio/react/dist/PrismicToolbar.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.24_patch_hash=e20141b84cb463162c7129fee49cad9a66c5487ed5d615a1bbe102c19552be4_73c71abda05b5558a168843fa78004f4/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.24_patch_hash=e20141b84cb463162c7129fee49cad9a66c5487ed5d615a1bbe102c19552be4_73c71abda05b5558a168843fa78004f4/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.24_patch_hash=e20141b84cb463162c7129fee49cad9a66c5487ed5d615a1bbe102c19552be4_73c71abda05b5558a168843fa78004f4/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["SquaresFour","UserCircle","Star","Heart","HourglassHigh","List"] */ "/app/packages/forge-icons/dist/index.js");
;
import(/* webpackMode: "eager" */ "/app/packages/forge/dist/index.js");
