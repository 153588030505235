'use client';

import React, { useEffect } from 'react';
import { Box, BoxProps, Heading, VisuallyHidden } from '@mezzoforte/forge';
import { merge, MergeFn } from '@/util/array';
import EntryCard from '@/components/EntryList/EntryCard';
import DummyEntryCard from '@/components/EntryList/DummyEntryCard';
import { EntryListBanner } from '@/components/Banners/EntryListBanner';
import { useFrontPageEntries } from '@/hooks/useFrontPageEntries';
import { ListEntry } from '@/types/ListEntry';
import { EntryListContainer } from '@/components/EntryList/EntryListContainer';
import { gtmService } from '@/util/gtm-service';
import { listEntryToEcommerceItem } from '@/util/ecommerce';
import { useCategories } from '@/hooks/useCategories';
import { FrontPageDocument, FrontPageDocumentDataBannersItem } from 'prismicio-types';

const DummyEntryCount = 300; // needs to roughly match expected number of entries

export interface DummyFrontpageItem {
  type: 'Dummy';
  key: string;
}

export interface EntryFrontpageItem {
  type: 'Entry';
  entry: ListEntry;
  key: string;
}

export interface BannerFrontpageItem {
  type: 'Banner';
  banner: FrontPageDocumentDataBannersItem;
  key: string;
}

export type FrontpageItem = DummyFrontpageItem | EntryFrontpageItem | BannerFrontpageItem;
type MapResult = [number, FrontpageItem];

// Merge function inserts a banner (from the left list) if its order number corresponds to the length of
// the result list so far. Otherwise, an auction entry is inserted.
const mergeFunction: MergeFn<MapResult, MapResult> = ([left], _, result) => (result.length + 1 >= left ? -1 : 1);
const Dummy = React.memo(DummyEntryCard);

function renderItem(item: FrontpageItem, index: number) {
  if (item.type === 'Entry')
    return (
      <EntryCard
        entry={item.entry}
        key={item.key}
        analytics={{ listId: 'front_page', listName: 'Front page', index }}
      />
    );
  if (item.type === 'Banner')
    return (
      <EntryListBanner
        {...item.banner}
        key={item.key}
      />
    );
  return <Dummy key={item.key} />;
}

function FrontPageEntryList({ prismicPage, ...props }: { prismicPage: FrontPageDocument } & BoxProps) {
  const { isPending, isError, data } = useFrontPageEntries();
  const { data: categoriesData } = useCategories();

  const { banners } = prismicPage.data;

  const sortedBanners = banners
    .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
    .map<MapResult>((banner) => [banner.order ?? 0, { type: 'Banner', banner, key: `banner-${banner.order}` }]);
  const entries =
    isPending || isError
      ? Array.from(
          { length: DummyEntryCount },
          (_, index): MapResult => [index + 1, { type: 'Dummy', key: `dummy-${index}` }]
        )
      : data.map<MapResult>((entry, index) => [index + 1, { type: 'Entry', entry, key: entry.id.toString() }]);

  const items = merge(mergeFunction, sortedBanners, entries).map(([_, item]) => item);

  useEffect(() => {
    if (data === undefined || categoriesData === undefined) {
      return;
    }

    gtmService.recommendedEcommerce.viewItemList(
      'front_page',
      'Front page',
      data.map((entry, index) => listEntryToEcommerceItem(entry, categoriesData.categories, index))
    );
  }, [data, categoriesData]);

  return (
    <Box {...props}>
      <VisuallyHidden>
        <Heading variant="h2">Kohteet</Heading>
      </VisuallyHidden>
      <EntryListContainer
        data-test="entry-list-container"
        display="grid"
        gridAutoFlow="dense"
        gridTemplateColumns={{ base: '1fr', md: 'repeat(auto-fit, minmax(300px, 1fr))' }}
      >
        {items.map(renderItem)}
      </EntryListContainer>
    </Box>
  );
}

export default FrontPageEntryList;
