import { Box, BoxProps, Flex, Heading } from '@mezzoforte/forge';
import { EntryListQuery, getEntries } from '@/features/EntryList/fetch';
import { NextLinkButton } from '@/components/Link/LinkButton';
import EntryCard from '@/components/EntryList/EntryCard';
import { entryListItemToListEntry } from '@/util/entry';

export function EntryListContainer({ ...props }: BoxProps) {
  return (
    <Box
      data-test="entry-list-container"
      display="grid"
      gridAutoFlow="dense"
      gridTemplateColumns={{ base: '1fr', md: 'repeat(auto-fit, minmax(300px, 1fr))' }}
      marginInline={{ base: '-1rem', md: 0 }}
      gap={{ base: 0, md: '1.5rem' }}
      {...props}
    />
  );
}

export interface EntryListByQueryProps {
  query: EntryListQuery;
  heading?: string;
  button?: {
    href: string;
    label: string;
  };
  analytics: {
    listId: Lowercase<string>;
    listName: string;
    sourceEntryId?: number;
  };
}

export async function EntryListByQuery({
  query: { categoryId, order, limit },
  heading,
  button,
  analytics,
}: EntryListByQueryProps) {
  const result = await getEntries(categoryId, order, limit);
  if (!result) return null;

  const entries = result.entries.map(entryListItemToListEntry);

  return (
    <>
      {heading && (
        <Heading
          variant="h3"
          as="h2"
          my={4}
        >
          {heading}
        </Heading>
      )}
      <EntryListContainer>
        {entries.map((entry, index) => (
          <EntryCard
            key={entry.id}
            entry={entry}
            analytics={{ ...analytics, index }}
          />
        ))}
      </EntryListContainer>
      {button && (
        <Flex
          justify="center"
          my={5}
        >
          <NextLinkButton
            href={button.href}
            variant="primary-hero"
          >
            {button.label}
          </NextLinkButton>
        </Flex>
      )}
    </>
  );
}
