'use client';

import { Content, SliceZone as SliceZoneT, TitleField } from '@prismicio/client';
import { PrismicRichText, SliceComponentProps } from '@prismicio/react';
import { RegistrationIconBlocksSlice } from 'prismicio-types';
import { GridItem, GridItemProps } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { Box, Flex, Text } from '@mezzoforte/forge';

import { registrationInfoIcons } from './util';

/**
 * Props for `RegistrationIconBlocks`.
 */
export type RegistrationIconBlockProps = SliceComponentProps<Content.RegistrationIconBlocksSlice>['slice'];

/**
 * Component for "RegistrationIconBlock" Slice.
 */
const RegistrationIconBlock = (slice: RegistrationIconBlockProps) => {
  const BlockIcon = registrationInfoIcons[slice.primary.icon ?? 'Gear'];
  return (
    <ContentListItem>
      <Flex justify="center">
        <BlockIcon
          size={24}
          weight="light"
        />
      </Flex>
      <Flex align="center">
        <Text fontSize={{ base: 14, md: 16 }}>{slice.primary.icon_text}</Text>
      </Flex>
    </ContentListItem>
  );
};

interface RegistrationIconBlocksProps extends GridItemProps {
  titleField: TitleField;
  blocks: SliceZoneT<RegistrationIconBlocksSlice>;
}

const MobileRegistrationIconBlocks = ({ titleField, blocks, ...gridItemProps }: RegistrationIconBlocksProps) => {
  return (
    <GridItem {...gridItemProps}>
      <Box padding={2}>
        <PrismicRichText field={titleField} />
        <ContentList
          data-slice-type="registration_icon_blocks"
          data-slice-variation="default"
        >
          {blocks.map((slice, index) => (
            <RegistrationIconBlock
              key={index}
              {...slice}
            />
          ))}
        </ContentList>
      </Box>
    </GridItem>
  );
};

export const RegistrationIconBlocks = ({
  titleField,
  blocks,
}: Pick<RegistrationIconBlocksProps, 'titleField' | 'blocks'>) => {
  return (
    <Box padding={2}>
      <Box paddingLeft={5}>
        <PrismicRichText field={titleField} />
        <ContentList
          data-slice-type="registration_icon_blocks"
          data-slice-variation="default"
        >
          {blocks.map((slice, index) => (
            <RegistrationIconBlock
              key={index}
              {...slice}
            />
          ))}
        </ContentList>
      </Box>
    </Box>
  );
};

export default MobileRegistrationIconBlocks;

const ContentList = styled.ul`
  list-style: none;
  margin-top: 1rem;
  padding: 0;
`;

const ContentListItem = styled.li`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 24px 1fr;
  margin-bottom: 1rem;
`;
