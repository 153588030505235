'use client';

import { Content } from '@prismicio/client';
import { PrismicLink, PrismicRichText, PrismicText, SliceComponentProps } from '@prismicio/react';
import { Box, Flex, useTokens } from '@mezzoforte/forge';
import { ArrowRight } from '@mezzoforte/forge-icons';
import styled from '@emotion/styled';

const { colors, radii, borders } = useTokens.huutokaupat;

/**
 * Props for `LinkGroup`.
 */
export type LinkGroupProps = SliceComponentProps<Content.LinkGroupSlice>;

/**
 * Component for "LinkGroup" Slices.
 */
const LinkGroup = ({ slice }: LinkGroupProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
    >
      <Box
        padding={4}
        border={borders.input}
        borderColor={colors.lineBorder}
        borderRadius={radii.box}
        minHeight={20}
        minWidth={{ md: 300, lg: 400 }}
        css={{
          p: {
            marginBlockStart: 0,
            marginBlockEnd: '0.5em',
          },
        }}
      >
        <PrismicRichText field={slice.primary.title} />
        {slice.primary.links.map((item, index) => (
          <Flex
            key={index}
            direction="column"
            justifyContent="space-between"
          >
            <PrismicLink field={item.link}>
              <CallToActionText>
                <PrismicText field={item.link_text} /> <ArrowRight size={16} />
              </CallToActionText>
            </PrismicLink>
          </Flex>
        ))}
      </Box>
    </section>
  );
};

export default LinkGroup;

const CallToActionText = styled.span`
  font-family: var(--chakra-fonts-button);
  align-items: center;
  color: ${colors.brand};
  display: inline-flex;
  gap: 0.5rem;

  &:hover {
    text-decoration: none;
  }
`;
