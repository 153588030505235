'use client';

import React from 'react';
import Image from 'next/image';
import { PrismicLink, PrismicRichText, PrismicText, SliceComponentProps } from '@prismicio/react';
import type { LinkBlocksSlice } from 'prismicio-types';
import { Box, Flex, Grid, useTokens } from '@mezzoforte/forge';
import { ArrowRight } from '@mezzoforte/forge-icons';
import styled from '@emotion/styled';

const { colors, space } = useTokens.huutokaupat;

import Envelope from '../../public/icons/Envelope.svg';
import Lifebuoy from '../../public/icons/Lifebuoy.svg';

const LinkBlocks = ({ slice }: SliceComponentProps<LinkBlocksSlice>) => {
  const icons = {
    Envelope,
    Lifebuoy,
  };

  return (
    <Grid
      as="section"
      autoColumns="1fr"
      autoFlow={{ base: 'row', md: 'column' }}
      gap={5}
      my={5}
      className="no-print"
    >
      {slice.items.map((item, index) => (
        <LinkBlock
          key={index}
          border={`1px solid ${colors.lineBorder}`}
          borderRadius="3px"
          textAlign="center"
        >
          <PrismicLink field={item.cta_link}>
            <Flex
              alignItems="center"
              flexDirection="column"
              justifyContent="space-between"
            >
              {item.icon && icons[item.icon] ? (
                <Image
                  src={icons[item.icon]}
                  alt=""
                  height={70}
                  width={70}
                />
              ) : null}
              <PrismicRichText field={item.text} />
              <CallToActionText>
                <PrismicText field={item.cta_text} /> <ArrowRight size={16} />
              </CallToActionText>
            </Flex>
          </PrismicLink>
        </LinkBlock>
      ))}
    </Grid>
  );
};

export default LinkBlocks;

const LinkBlock = styled(Box)`
  a {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    color: ${colors.text};
    display: flex;
    height: 100%;
    text-decoration: none;
    padding: ${space[3]} ${space[6]};

    &:hover {
      background-color: ${colors.backgroundGray};
      text-decoration: none;
    }
  }
`;

const CallToActionText = styled.span`
  align-items: center;
  color: ${colors.brand};
  display: inline-flex;
  gap: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`;
